* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  color: red;
  margin: 1rem 0;
}

.form_container {
  width: 70%;
  background: rgb(175 145 145);

  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input_Wrapper {
  width: 100%;
}

form {
  margin-top: 30px;
  width: 70%;
}

.input_Wrapper i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  color: #bababa;
  margin: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
}

.v-icon {
  color: #66180d;
  margin: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  right: 0;
  top: 0;
}

.input-field {
  width: 100%;
  padding: 15px 50px;
  border: 1px solid gray;
  outline: none;
}

.input-field::placeholder {
  color: #bababa;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.input_Wrapper {
  position: relative;
  margin-bottom: 15px;
}

.Fileupload_wrapper,
.preview_wrapper {
  text-align: center;
  margin-bottom: 30px;
}

.preview_wrapper {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.fileupload,
.preview {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
