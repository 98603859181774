.menu-for-mobile {
  display: none;
}
@media screen and (max-width: 1050px) {
  .menu-for-mobile {
    display: block;
  }
  .menu-options-list {
    display: none;
  }
}

.menu-for-mobile .hamburger-icon-cover {
  width: 20px;
  margin-right: 20px;
}
@media screen and (max-width: 576px) {
  .menu-for-mobile .hamburger-icon-cover {
    margin-right: 15px;
  }
}
.menu-for-mobile .hamburger-icon-cover img {
  width: 100%;
  display: table-cell;
}
.header-cover .menu-for-mobile .menu-list-cover {
  position: fixed;
  background-color: white;
  width: 300px;
  left: -105%;
  top: 70px;
  height: calc(100% - 70px);
  overflow: auto;
  z-index: 999999;
  bottom: 0px;
  box-shadow: 3px 0px 3px 1px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
@media screen and (max-width: 576px) {
  .header-cover .menu-for-mobile .menu-list-cover {
    width: 200px;
  }
}

.header-cover .menu-for-mobile .menu-list-cover.transition {
  left: 0px;
}

.menu-for-mobile .menu-list-cover ul {
  padding: 0px 4%;
}
.menu-for-mobile .menu-list-cover ul li:not(:last-child) {
  margin-bottom: 2px;
}
.menu-for-mobile .menu-list-cover ul li a {
  padding: 10px 15px;
  height: auto;
  border-radius: 8px;
  color: #19306a;
  font-weight: 600;
  position: relative;
}
.menu-for-mobile .menu-list-cover ul li a.active {
  background: linear-gradient(to right, #152567, #53c392);
  color: white;
  font-weight: bold;
}

.header-cover .right-portion .profile-cover {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.header-cover .right-portion .profile-cover .user-image-cover {
  width: 35px;
  height: 35px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.header-cover .right-portion .profile-cover .user-image-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-cover .right-portion .profile-cover .menu.transition {
  top: 32px;
  z-index: 999999;
  right: 42px !important;
  min-width: 150px;
}
.header-cover .right-portion .profile-cover .dropdown.icon {
  color: white;
  font-size: 24px;
}
.header-cover .right-portion .profile-cover .menu > .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px 10px 10px !important;
}
.header-cover .right-portion .profile-cover .menu > .item:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}
.notification-count {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #53c392;
  width: 18px;
  border-radius: 50%;
  height: 17px;
  font-size: 10px;
}
.header-cover .right-portion .profile-cover .menu > .item.selected {
  background-color: white;
}
.header-cover .right-portion .profile-cover .menu > .item.selected:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.header-cover .right-portion .profile-cover .menu > .item .icon {
  font-size: 20px;
  color: #53c392;
  line-height: normal;
  margin-right: 10px;
}
.header-cover .right-portion .profile-cover .ui.dropdown .menu .active.item {
  font-weight: 100;
}

@media screen and (max-width: 1300px) {
  .header-cover .right-portion .profile-cover {
    margin-left: 15px;
  }
  .header-cover .right-portion .profile-cover .user-image-cover {
    width: 40px;
    height: 40px;
  }
}

.header-cover {
  padding: 0px 4%;
  background-image: linear-gradient(to right, #152567, #53c392);
  /* background-color: #53C392; */
}
.header-cover .left-portion {
  display: flex;
  align-items: center;
}
.header-cover .left-portion .logo-image-cover {
  height: 50px;
}
.header-cover .left-portion .logo-image-cover img {
  height: 100%;
}
.header-cover .left-portion .logo-image-cover.circular-image {
  border-radius: 50%;
  width: 50px;
  overflow: hidden;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.header-cover .left-portion .logo-image-cover.circular-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header-cover .right-portion {
  display: flex;
  padding-top: 0px;
  align-items: center;
}
.header-cover .right-portion .slider-cover {
  text-align: center;
  color: white;
  margin-right: 10px;
  min-width: 71px;
  font-size: 12px;
}
.header-cover .left-portion a {
  height: 50px;
  width: auto;
  display: block;
}
.header-cover .left-portion a img {
  height: 100%;
  width: auto;
}

.header-cover .menu-options-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 17px;
}
.menu-options-list-display {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu-options-list .single-menu {
  position: relative;
  margin: 0px;
}
.menu-options-list .single-menu:not(:last-child)::after {
  content: "";
  position: absolute;
  height: 20px;
  transform: translateY(-50%);
  right: 0px;
  width: 1px;
  top: 50%;
  background-color: #d9d9d9;
}
.menu-options-list .single-menu.active::after {
  display: none;
}
.menu-options-list .single-menu a {
  color: white;
  padding: 13px 15px 13px;
  font-size: 13px;
  min-width: auto;
  line-height: 20px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  text-align: center;
  display: block;
  transition: 0.2s;
}

.menu-options-list .single-menu.active a {
  color: #53c392;
  background: white;
  border-radius: 8px 8px 0px 0px;
}

.menu-options-list .single-menu.active a::before {
  content: "";
  /* background-image: url("../../assets/header-curve-left.svg"); */
  position: absolute;
  bottom: -1px;
  left: -7px;
  height: 9px;
  background-repeat: no-repeat;
  width: 8px;
}
.menu-options-list .single-menu.active a::after {
  content: "";
  /* background-image: url("../../assets/header-curve-right.svg"); */
  position: absolute;
  bottom: -1px;
  right: -5px;
  height: 9px;
  background-repeat: no-repeat;
  width: 8px;
}

.menu-options-list .single-menu.active {
  margin-left: -1px;
}

@media screen and (max-width: 1300px) {
  .header-cover {
    padding: 0px 45px 0px 30px;
  }
  .header-cover .right-portion {
    align-items: flex-end;
  }
  .header-cover .left-portion a {
    height: 35px;
  }
  .header-cover .right-portion .profile-cover {
    margin: 10px 0px 10px 15px;
  }
  .menu-options-list .single-menu a {
    color: white;
    padding: 10px;
    font-size: 13px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1050px) {
  .header-cover {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-cover .right-portion .menu-options-list {
    display: none !important;
  }
  .header-cover .right-portion {
    padding-top: 0px;
  }
  .header-cover .right-portion .profile-cover {
    margin: 0px;
  }
  .header-cover .menu-for-mobile .menu-list-cover {
    top: 70px !important;
  }
  .header-cover .right-portion .profile-cover .menu.transition {
    top: 32px;
    right: 40px !important;
  }
}

@media screen and (max-width: 992px) {
  .header-cover .right-portion .slider-cover {
    transform: scale(0.9);
    margin-right: 0px;
  }
}
@media screen and (max-width: 576px) {
  .header-cover .right-portion .slider-cover {
    transform: scale(0.7);
  }
  .header-cover {
    padding: 10px 30px 10px 15px;
  }
}
