/* .upload{
    text-align :center;
    margin-bottom: 10px;
   margin-top: -5px;
}
.btn{
    width: 40%;


}
.div{
    display: flex;
    justify-content: space-between;
} */

.text-blue {
  color: #152567;
}
