.addInfo .inputField {
  padding: 0.67857143em 1em;
  background-color: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: -5px -5px 10px 0 rgb(255 255 255 / 80%);
  width: 90%;
  margin-top: 5px;
}
.field {
  width: 33%;
}
.addInfo {
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid #ffffff6b;
  box-shadow: -5px -5px 8px 0 rgb(255 255 255 / 90%),
    5px 5px 8px 0 rgb(0 0 0 / 10%);
  background-image: linear-gradient(118deg, #e8ebed 10%, #edf0f2 47%);
  width: 85%;
  margin: 0 auto;
}
.firstRow {
  /* display: flex; */
}
.row {
  display: flex;
}
.mt15 {
  margin-top: 25px;
}

.field .search input {
  padding: 0.67857143em 1em;
  background-color: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem !important;
  box-shadow: -5px -5px 10px 0 rgb(255 255 255 / 80%);
  width: 90%;
  width: 100%;
  /* margin: 0 !important; */
}

.field .search .input {
  margin: 0 !important;
  width: 90%;
}
.inputFieldSelect {
  margin-top: 20px !important;
}
.inputFieldSelect .MuiSelect-select {
  padding: 0.47857143em 1em;
}
/* .MuiSelect-select {
  padding: 0px !important;
} */

.container {
  background-color: rgb(237 240 242 / 1);
  padding: 50px 0px;
  /* height: 100vh; */
}

.results {
  width: 90% !important;
}

.videoAndField {
  display: flex;
}
.leftContainer {
  /* flex: 2; */
  width: 100%;
}
.selection {
  width: 90% !important;
}
.rightContainer {
  /* flex: 1; */
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.videoStream {
  background-color: black;
  border-radius: 5px;
}
/********************************Popup CSS********************************/
.popup-cover {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 100px 0px 50px;
  overflow-y: auto;
  z-index: 9999999;
  left: 0;
}
.popup-cover ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.7);
}

.popup-ui {
  width: 450px;
  max-width: 95%;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  background: #ebeef0;
  padding: 0px;
  border-radius: 7px;
}

.popup-ui .popup-header {
  padding: 12px 35px;
  border-radius: 5px 5px 0px 0px;
  background: #36787e;
  color: white;
  text-align: center;
  position: relative;
}

.popup-ui .popup-header .close-popup {
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 15px;
}

.popup-ui .popup-header h3 {
  font-weight: 100;
  font-size: 20px;
  color: white;
}
.popup-ui .popup-body {
  padding: 20px 20px 10px;
}
@media screen and (max-width: 576px) {
  .popup-ui .popup-body {
    padding: 15px;
  }
}
.popup-ui .popup-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15px 20px;
}
.popup-ui .popup-footer .btn {
  margin: 5px;
}

.close-popup {
  opacity: 0.8 !important;
  cursor: pointer;
}

.close-popup:hover {
  opacity: 1 !important;
}

.secondary-popup-ui {
  background: white;
  max-width: 95% !important;
}
.secondary-popup-ui .popup-header {
  color: black !important;
  background: white;
  padding: 20px 20px 0px;
  position: relative;
}
.secondary-popup-ui .popup-header .close-cover {
  position: absolute;
  right: 10px;
  top: 24px;
  cursor: pointer;
}
.secondary-popup-ui .popup-header .close-cover .icon {
  font-size: 20px;
}
.secondary-popup-ui .popup-header h2 {
  color: black;
  font-weight: bold;
  margin-bottom: 0px;
}
.secondary-popup-ui .popup-body {
  padding: 20px 30px;
}
.secondary-popup-ui .popup-footer {
  padding: 0px 20px 20px;
}

@media screen and (max-width: 576px) {
  .secondary-popup-ui .popup-body {
    padding: 15px 20px;
  }
  .secondary-popup-ui .popup-header .close-cover {
    top: 19px;
  }
}
.mt10 {
  margin-top: 10px;
}

/*****Buttons *****/
.btn {
  padding: 10px 8px;
  border-radius: 5px;
  color: #53c392;
  box-shadow: none;
  transition: 0.2s;
  /* height: 49px; */
  white-space: nowrap;
  /* min-width: 90px; */
  outline: none;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  border: 1px solid #53c392;
  background-color: white;
  box-shadow: -5px -5px 8px 0 rgba(255, 255, 255, 0.9),
    5px 5px 8px 0 rgba(0, 0, 0, 0.1);
}
.ui.active.dimmer {
  background-color: #00000061;
  transition: 0.3s;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  display: none !important;
  z-index: 9999999;
}
.btn.btn-primary {
  border: 1px solid #eff1f2;
  color: white;
  background-image: linear-gradient(to right, #152567, #53c392);
}
.btn.btn-delete {
  background-color: #ed5e68;
  color: white;
  border: none;
}
.btn.btn-simple {
  background-color: #8b8b8b;
  color: white;
  border: none;
}
.btn.btn-disable {
  background-color: #e5e5e5;
  color: #c4c4c4;
  border: none;
}
.btn.btn-simple:hover {
  background-color: #7a7a7a;
}
.btn.small {
  padding: 10px 20px;
  height: auto;
  min-width: 70px;
  font-size: 14px;
}
.btn.w-100 {
  width: 100%;
}
.btn.on-load {
  color: #ffffff96;
  cursor: progress;
}

.btn.on-load .dimmer {
  display: block !important;
}
.btn:disabled,
.btn.disabled {
  opacity: 0.75;
  cursor: not-allowed;
}

.w100 {
  width: 100% !important;
}

@media screen and (max-width: 576px) {
  .btn {
    height: 40px;
    min-width: 70px;
    font-size: 14px;
    padding: 10px 20px;
  }
  .btn.small {
    padding: 5px 13px;
    height: auto;
    min-width: 50px;
    font-size: 12px;
  }
}

.btn.small .ui.loader,
.btn.small .ui.loader::before,
.btn.small .ui.loader::after {
  width: 20px;
  height: 20px;
}
.btn.small .ui.loader:before,
.btn.small .ui.loader:after {
  margin-left: -10px;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}
/************************/
